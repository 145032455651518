/*/
  colors
/*/

$black: #2c2e2f;
$blue-light: #96e0f9;
$blue-medium: #62a9c3;
$gray-dark: rgb(67, 67, 67); //rgb(64, 67, 69);
$gray-darker: rgba(72, 73, 77, 1);
$gray-light: #a8a8a9;
$gray-medium: #5b5e5f;
$red-faded: rgb(228, 119, 114); // rgba(228, 119, 114, .75);
$red-faded-on-dark: rgba(228, 119, 114, .75);
$red-faded-on-light: rgba(228, 119, 114, .85); // rgba(228, 119, 114, .75);
$white: #ffffff;

$primary: $red-faded-on-light !default;
/*
  $secondary:     $gray-600 !default;
  $success:       $green !default;
  $info:          $cyan !default;
  $warning:       $yellow !default;
  $danger:        $red !default;
  $light:         $gray-100 !default;
  $dark:          $gray-800 !default;
*/


/*/
  dimensions
/*/

$logo-gutter-xs: 7px;
$logo-height-md: 82px;
$logo-height-min: 48px;
$logo-height-max: 92px;

$logo-aspect-ratio: 576 / 184;
$icon-aspect-ratio: $logo-aspect-ratio; //300 / 184;

$nav-height-xs: 72px;
$nav-padding-min: 24px;
$nav-padding-max: 84px;


/*/
  imports
/*/

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";


/*/
  variables
/*/

$assets: "../assets/";


/*/
  functions
/*/


/*/
  page
/*/

body {
    background: $white;
    color: $gray-dark;
}



/*/
  background images
/*/

@function background-url($path, $size: 2732) {
  @return $assets + $path + "-bg-" + $size + ".jpg";
}

@mixin background-photo-new($path, $x-focus: 50%, $y-focus: 50%) {

  background-image: url(background-url($path, 5120));

  @media (max-width: 1920px) {
    background-image: url(background-url($path, 3840));
  }

  @media (max-width: 1440px) {
    background-image: url(background-url($path, 2880));
  }

  @media (max-width: 1366px) {
    background-image: url(background-url($path, 2732));
  }

  @media (max-width: 1024px) {
    background-image: url(background-url($path, 2048));
  }

  @media (max-width: 768px) {
    background-image: url(background-url($path, 1536));
  }

  @media (max-width: 414px) {
      background-image: url(background-url($path, 828));
  }

  background-position: left $x-focus top $y-focus;
  background-size: cover;
  background-repeat: no-repeat;

}

@mixin background-color-adjust($r, $g, $b, $o) {

  position: relative;

  &::before {
    background-color: rgba($r, $g, $b, $o);
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

}

@mixin background-photo($path, $x-focus: 50%, $y-focus: 50%) {

  background-image: url(background-url($path, 5120));
/*
  @media (max-width: 1920px) {
    background-image: url(background-url($path, 3840));
  }

  @media (max-width: 1440px) {
    background-image: url(background-url($path, 2880));
  }
*/
  @media (max-width: 1366px) {
    background-image: url(background-url($path, 2732));
  }

  @media (max-width: 1024px) {
    background-image: url(background-url($path, 2048));
  }

  @media (max-width: 768px) {
    background-image: url(background-url($path, 1536));
  }

  @media (max-width: 414px) {
//    background-image: url(background-url($path, 828));
    background-image: url(background-url($path, 750));
  }

  background-position: left $x-focus top $y-focus;
  background-size: cover;
  background-repeat: no-repeat;

}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
         only screen and (-moz-min-device-pixel-ratio: 1.5),
         only screen and (-o-min-device-pixel-ratio: 3 / 2),
         only screen and (min-device-pixel-ratio: 1.5),
         only screen and (min-resolution: 1.5dppx) {
    @content;
  }
}

@mixin container-padding($size: false) {

  @if $size == xs {
    padding: 4rem 2rem;
  } @else {
    padding: 100px 0;
  }
}


/*/
  nav bar
/*/

nav.navbar {

  background: transparent;
  padding: $nav-padding-max 0;
  font-size: 17%;
  transform: translate3d(0, 0, 0);

  .navbar-nav {

    // Align with logo text.
    margin-bottom: -5px;

    .nav-item {
      padding: 3px 0 0 0;
      padding-right: 38px;
    }

    .nav-item:last-of-type {
      padding-right: 0;
    }

    .nav-link {
      border: 4px solid transparent;
      border-left-width: 0;
      border-right-width: 0;
      color: $gray-dark;
      font-family: "DINNextLTPro-Bold";
      font-size: 17px;
      height: 32px;
      line-height: 17px;
      padding: 6px 0;
      text-transform: uppercase;
    }

  }

  .nav-item.active .nav-link {
    border-bottom: 4px solid $blue-light;
  }

  .navbar-brand {

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    height: $logo-height-max;
    margin: 0;
    padding: 0;
    width: 100%;

    a {

      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);

      background: url($assets + "logo.svg") no-repeat;
      background-origin: content-box;
      background-position: right center;
      background-size: contain;
      display: block;
      height: 100%;
      overflow: hidden;
      text-indent: -2000px;
      width: 100%;

    }

  }

  @mixin transition($duration) {
    -webkit-transition: $duration;
    -moz-transition: $duration;
    -ms-transition: $duration;
    -o-transition: $duration;
    transition: $duration;
  }

  &.large {

    background: rgba(255, 255, 255, 0);

    transition-timing-function: linear;
    @include transition(.5s);

    padding: $nav-padding-max 0;

    .navbar-brand {

      @include transition(.5s);

      height: $logo-height-max;

    }

  }

  &.small {

    background: rgba(255, 255, 255, .9);

    transition-timing-function: linear;
    @include transition(.5s);

    padding: $nav-padding-min 0;

    .navbar-brand {

      @include transition(.5s);

      height: $logo-height-min;

    }

  }

  &.scrolling .navbar-brand a {
    background-image: url($assets + "icon@2x.png");
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  @include media-breakpoint-down(md) {

    .navbar-nav {
      // Align with logo text.
      margin-bottom: -4px;
    }

    .navbar-brand {

      height: $logo-height-md;

      @include media-breakpoint-up(md) {
        padding-left: 25px;
      }

      a {
        background-image: url($assets + "logo.svg");
      }

    }

    .navbar-nav {

      .nav-item {
        padding-right: 25px;
      }

      .nav-link {
        font-size: 14px;
        line-height: 14px;
        height: 26px;
        padding: 3px 0;
      }

    }

  }

}

@media (max-width: 767.98px),
       (max-width: 991.98px) and (max-height: 100vw) {

  nav.navbar {

    .container {
      margin: 0 0 0 30px;
    }

    .navbar-nav {

      // Align with logo text.
      margin-bottom: -4.5px;

      .nav-item {
        padding-right: 25px;
      }

      .nav-link {
        font-size: 13px;
        line-height: 13px;
        padding: 2px 0 1px 0;
        height: auto;
      }

    }

    .navbar-toggler {
      border-color: transparent;
      position: absolute;
      left: 18px;
      top: 18px;
    }

    background: rgba(255, 255, 255, 0.95) url($assets + "logo.svg") right $logo-gutter-xs top $logo-gutter-xs no-repeat;
    background-size: $logo-aspect-ratio * ($nav-height-xs - 2 * $logo-gutter-xs);
    min-height: $nav-height-xs;
    padding: 0px;
    transform: translate3d(0, 0, 0);

    .navbar-collapse {

      &.show,
      &.collapsing {

        border-top: 1px solid rgba(0, 0, 0, .1);
      //background: yellow;
        margin-top: $nav-height-xs;
        padding: 0;

        .container {
          margin: 0;
          padding: 0;
        }

        .navbar-nav {
          padding: 15px 0;
        }

      }
    }

    &.scrolling {
      background-image: url($assets + "icon@2x.png");
      background-size: $icon-aspect-ratio * ($nav-height-xs - 2 * $logo-gutter-xs);
    }

    .click-target {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 200px;
    }

    .container {
      padding: 0;
    }

    .navbar-brand {
      display: none;
    }

    @media (max-width: 575.98px) {

      .navbar-nav {
      }

      .nav-item .nav-link {
        border: 0;
        border-left: 5px solid transparent;
        padding: 2px 0 0 13px;
        margin: 0 0 0 15px;
        line-height: 34px;
      }

      .nav-item.active .nav-link {
        border-bottom: none;
        border-left-color: $blue-light;
      }

    }

  }

}

/*/
  footer
/*/

#footer {

  background: $gray-dark;
  color: white;
  padding-bottom: 167px;
  padding-top: 50px;

  // 64, 67, 69
  @include background-color-adjust(0, 80, 120, .04);

  @include media-breakpoint-down(md) {
    padding-bottom: 90px;
  }

  @include media-breakpoint-down(xs) {
    padding-bottom: 4rem;
    padding-top: .75rem;
  }

  h3 {
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      font-size: 11px;
    }

  }

  p {

    color: $gray-light;
    font-family: "AvenirNextLTPro-Medium";
    font-size: 14px;
    margin: 2px 0 0 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      font-size: 8.5px;
      margin-top: .25rem;
    }

    a {
      color: $gray-light;
      text-decoration: underline;
    }

  }

  a {

    color: white;

    &.click-target {
      height: 100%;
      position: absolute;
      width: 100%;
    }

  }

  .one {

    padding-bottom: 42px;
    padding-top: 59px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding-bottom: 0;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
    }

  }

  .two {

    background: url($assets + "logo-footer.svg") right 15px center no-repeat;
    background-size: 284px;
    min-height: 92px;

    @include media-breakpoint-down(md) {
//      background-position: left 15px center;
      background-size: 290px;
      min-height: 155px;
      background-position: center top;
      margin-top: 3rem;
      border-bottom: 1px solid $gray-light;
    }

    @include media-breakpoint-down(sm) {
      background-size: 234px;
      min-height: 129px;
    }

  }

}


/*/
  content blocks
/*/

body main, body footer, body .pswp {
//  font-size: 100px;
}

main {

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }

  @include media-breakpoint-down(xs) {
//    font-size: 80px;
  }

}

h1 {
  font-family: "AvenirNextLTPro-Bold";
  font-size: 45%;
  font-size: 45px;
  line-height: 1.1;
  margin-bottom: 2rem;

  @include media-breakpoint-down(xs) {
    font-size: 11.25vw;
  }

}

h2 {
  font-family: "AvenirNextLTPro-Medium";
  font-size: 28%;
  font-size: 28px;
  margin-bottom: 1rem;
}

h3 {
  font-family: "DINNextLTPro-Bold";
  font-size: 18%;
  font-size: 18px;
  margin: 0 0 24px 0;
  text-transform: uppercase;
}

p, li {
  font-family: "AvenirNextLTPro-Regular";
  font-size: 18%;
  font-size: 18px;
}


main ul {

  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding-left: 32px;
    background: url($assets + "bullet-nine-square@2x.png") left top .4em no-repeat;
    background-size: 8px;

    @include media-breakpoint-down(xs) {
      margin: 0 0 .5em 0;
    }

    @include media-breakpoint-up(lg) {
      line-height: 1.3;
      margin-bottom: .5rem;
    }
  }

}

p.summary {
  font-size: 24%;
  font-size: 24px;
  margin: 36px 0;
}

hr {
  margin: 53px 0;
}

.tech-link {

  font-family: "AvenirNextLTPro-Demi";
  font-size: 14%;
  font-size: 14px;
  margin: 30px 0 0 0;
  padding: 0;

  li {
    font-size: inherit;
    list-style-type: none;
    margin: 8px 0 0 0;
    padding-left: none;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  span a,
  span a:hover {
    text-decoration: underline;
  }

  .down {
    background: url($assets + "down@2x.png") left 0 top 5px no-repeat;
  }

  .plus {
    background: url($assets + "plus@2x.png") left .11em no-repeat;
  }

  .launch {
    background: url($assets + "launch@2px.png") left .176em no-repeat;
  }

  .down, .plus, .launch {
    background-size: 1em;
    display: block;
    padding-left: 2.5em;
  }

}

.no-break {
  white-space: nowrap;
}


/*/
  controls
/*/

a.btn {
  border: none;
  border-radius: 50px;
  color: white;
  font-family: "DINNextLTPro-Bold";
  font-size: 18px;
  line-height: 46.5px;
  margin: 32px 0;
  min-width: 232px;
  padding: 2.5px 25px 0;
  text-transform: uppercase;
}

a.btn-primary:hover {
  background: $red-faded;
  border-color: $red-faded;
}


/*/
  dark backgrounds
/*/

.dark {

  background-color: $gray-dark;
  color: $white;

  a.btn-primary {

    background: $red-faded-on-dark;
    border-color: $red-faded-on-dark;

    &:hover {
      background: $red-faded;
      border-color: $red-faded;
    }

  }

  h1 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h2 {
    color: $blue-medium;
  }

  hr {
    background-color: $gray-medium;
  }

  a {
    color: $white;
  }

  .tech-link {

    font-family: "AvenirNextLTPro-Medium";

    .plus {
      background-image: url($assets + "plus-light@2x.png");
    }

    .down {
      background-image: url($assets + "down-light@2x.png");
    }

    .launch {
      background-image: url($assets + "launch-light@2x.png");
    }

  }

}

/*/
  container and grid
/*/

@include media-breakpoint-down(xs) {

  .container, .container-fluid {
    .container, .container-fluid {
      .container, .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-lg-6, .col-lg-4, .col-lg, .col-md-6, .col, .col-sm, .col-md {
    padding-left: 0;
    padding-right: 0;
  }

}


/*/
  shared sections
/*/

#hero {

  background-color: white;
  border-radius: unset;
  margin-bottom: 0;
  padding: 280px 0 100px 0;

  h1 {
    margin-bottom: 0;
  }

  p.summary {
    margin-top: 12px;
  }

  .tech-link {
    margin: 100px 0 0 0;
  }

  @include media-breakpoint-down(md) {
    padding-top: 17rem;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;

    .tech-link {
      margin-top: 54px;
    }
  }

  @include media-breakpoint-down(xs) {

    h1 {
      margin-bottom: 1.5rem;
    }

    .btn-primary {
      margin-bottom: 0;
      margin-top: 1.5rem;
    }

  }

}

#top-services {

  padding-top: 100px;
  padding-bottom: 82px;

  h1 {
    margin-top: -12px;
  }

  h3 {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 32px;
    display: block;
    line-height: 32px;
    padding-left: 51px;
    margin-top: 16px;
  }

  #custom-software h3 {
    background-image: url($assets + "custom-software-icon@2x.png");
  }

  #user-interfaces h3 {
    background-image: url($assets + "user-interfaces-icon@2x.png");
  }

  #analysis h3 {
    background-image: url($assets + "analysis-icon@2x.png");
  }

  #training h3 {
    background-image: url($assets + "training-icon@2x.png");
  }

  @include media-breakpoint-down(xs) {
    @include container-padding(xs);
  }

}




/*/
  Page: home
/*/

.home {

  #hero {

    @include background-photo-new("home/hero", 50%, 100%);
    background-color: white;

  }

  #sound-science {

    @include background-photo-new("home/sound-science", 50%, 100%);
    background-color: rgb(67, 67, 67);
    padding: 0;
    background-size: 100%;

    // 64, 67, 69
    @include background-color-adjust(0, 80, 120, .04);

    @include media-breakpoint-down(md) {
      background-size: 100%;
    }

    @include media-breakpoint-down(xs) {
      background-size: 100%;
    }

  }

  #specializing {

    border-top: 1px solid $gray-medium;

    @include container-padding();

    h1 {
      margin-bottom: 4rem;
    }

    @include media-breakpoint-down(xs) {

      @include container-padding(xs);

      .row:first-of-type p {
        margin-bottom: 2.25rem;
      }

      .row:first-of-type .col-md:last-of-type p {
        margin-bottom: 1rem;
      }

      .row:last-of-type > div {
        margin-top: 3.5rem;
      }

      h1 {
        margin-bottom: 3rem;
      }

    }

    h2 {

      background-position: top right;
      background-repeat: no-repeat;
      background-size: 50%;
      display: inline-block;
      margin-bottom: 1rem;
      background-position: right bottom 10px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 2rem;
      }

      &#air-quality {

        background-image: url($assets + "air-quality-icon@2x.png");
        background-size: 138px;
        padding-right: 165px;
        padding-top: 5px;

        @include media-breakpoint-down(xs) {
          margin-bottom: .5rem;
          padding-top: 4px;
          padding-right: 147px;
          background-size: 130px;
        }

      }

      &#seismology {

        background-image: url($assets + "seismology-icon@2x.png");
        background-size: 59px;
        padding-top: 20px;
        padding-right: 70px;

      }

      &#geospatial {

        background-image: url($assets + "geospatial-icon@2x.png");
        background-size: 27px;
        padding-top: 20px;
        padding-right: 40px;

      }

      &#resource-management {
        background-image: url($assets + "resource-management-icon@2x.png");
        background-size: 43px;
        padding-right: 59px;
        padding-top: 20px;

        @include media-breakpoint-down(xs) {
          margin-top: 3.5rem;
          padding-right: 48px;
          padding-top: 0;
        }

      }

    }

    h3 {
      margin: 0 0 3px 0;
    }

    p.summary {
      margin-top: 23px;
    }

    #see-our-work {
      margin-top: 90px;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        margin-top: 2.25rem;
      }

    }

    @include media-breakpoint-down(xs) {
      hr {
        display: none;
      }
    }

  }

  #new-in-the-lab {

    @include background-photo-new("home/in-the-lab", 100%, 75%);
    background-color: white;
    background-size: cover;
    color: $black;
    padding: 150px 0;

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(md) {
      background-position: left 100% top 100%;
    }

    @include media-breakpoint-down(sm) {
      background-position: left 100% top 100%;
    }

    @include media-breakpoint-down(xs) {
      background-position: left 50% bottom;
      padding: 3.25rem 1rem 4rem 1rem;
    }

    @include media-breakpoint-up(xl) {
      height: 47vw;
      display: flex;
      align-items: center;
    }

    h2 {

      background: url($assets + "monitor-icon@2x.png") right bottom no-repeat;
      background-size: 69px;
      color: $blue-medium;
      display: inline-block;
      height: auto;
      line-height: 1.2;
      padding-right: 84px;
      background-position: right bottom .34em;
      padding: 38px 84px 0 0;
      vertical-align: bottom;

      @include media-breakpoint-down(xs) {
        background-position: right bottom .34em;
        background-size: 60px;
        height: auto;
        line-height: 1.2;
        margin-bottom: 1.25rem;
        padding-top: 0;
        width: auto;
      }

    }

    a.btn {

      margin: 20px 0 0 0;

      @include media-breakpoint-down(xs) {
        margin-top: 1.5em;
      }

    }

  }

  #pre-footer {
    /*
    65,66,70
    64,67,69
    .9846, 1.0152, .9857
    */

    background-color: rgb(76, 76, 76);
    @include background-photo-new("home/pre-footer", 50%, 0%);
    background-size: cover;
    padding-bottom: 129px;
    padding-top: 129px;

    //71, 74, 76
    @include background-color-adjust(0, 40, 80, .07);

    @include media-breakpoint-down(lg) {
      background-position: left 50% top 0%;
    }

    @include media-breakpoint-down(md) {
      background-position: left 50% top 0%;
    }

    @include media-breakpoint-down(xs) {
      background-position: left 50% top 0%;
      padding: 4rem 0;
    }

    #jon-and-leska {
      background: url($assets + "home/jon-and-leska-890.jpg") center center no-repeat;
      background-size: cover;
      border-radius: 100%;
      height: 0;
      overflow: hidden;
      padding: 100% 0 0 0;
      max-width: 475px;
      width: 100%;

      @include media-breakpoint-down(xs) {
        background-image: url($assets + "home/jon-and-leska-630.jpg");
      }

    }

    .inner {

      padding-left: 36px;
      color: $white;

      @include media-breakpoint-down(sm) {
          padding-left: 0;
          padding-top: 2rem;
      }

    }

    p {
      margin: 0;
    }

  }

}



/*/
  Page : Services
/*/

.services {

  #hero {
    @include background-photo-new("services/hero", 50%, 100%);
  }

}

.services #services-wrapper {

  @include background-photo-new("services/services", 100%, 100%);
  background-size: 100%;

  @include media-breakpoint-down(xs) {
    padding: 0;
  }

  // 64, 67, 69
  @include background-color-adjust(0, 80, 120, .04);

}

.services #team {

  border-top: 1px solid $gray-medium;
  padding-bottom: 84px;
  padding-top: 63px;

  hr {
    margin: 3rem 0;
  }

  .principal, .intern {
    background-position: left 15px top;
    background-repeat: no-repeat;

  }

  .principal {
    background-size: 284px;
    padding-top: 333px;

    p {
      margin-bottom: 3em;
    }

    .tech-link {
      position: absolute;
      bottom: 0;
    }

  }

  .intern {
    background-size: 189px;
    padding-top: 227px;
  }

  @include media-breakpoint-down(md) {

    hr {
      display: none;
    }

    .principal, .intern {

      margin-top: 3rem;

    }

    .principal {
      &:first-of-type {
        margin-top: 0;
      }
    }

    .intern {
      p {
        margin-bottom: 0;
      }
    }

    a.btn {
      margin: 3rem 0 0 0;
    }

  }

  @include media-breakpoint-down(xs) {

    padding: 4rem 0;

    hr {
      display: none;
    }

    h1 {
      padding: 0 2rem;
    }

    .row {
      padding: 0 2rem;
    }

    .principal, .intern {
      background-position: left 0px top;
    }

    .principal {

      background-size: contain;
      margin-bottom: 1.5rem;
      margin-top: 1rem;
      padding-top: 100%;

      p {
        margin-bottom: 2.5em;
      }

      h2 {
        margin-top: 2rem;
      }

    }

    .intern {
      padding-top: 227px;
      margin-bottom: 1.5rem;
      margin-top: 1rem;
    }

    a.btn {
      margin: 1rem 2rem 0;
    }

  }

  div[alt="Jon Callahan"] {
    background-image: url($assets + "/services/people/Jon.png");
  }

  div[alt="Leska Fore"] {
    background-image: url($assets + "/services/people/Leska.png") ;
  }

  div[alt="Hans Martin"] {
    background-image: url($assets + "services/people/Hans.png");
  }

  div[alt="Helen Miller"] {
    background-image: url($assets + "services/people/Helen.png");
  }

  div[alt="Spencer Pease"] {
    background-image: url($assets + "/services/people/Spencer.png");
  }

  div[alt="Tate Brasel"] {
    background-image: url($assets + "/services/people/Tate.png");
  }

}

.services #pre-footer {

  @include background-photo-new("services/pre-footer", 100%, 100%);

  background-size: 100%;
  padding-bottom: 91px;
  padding-top: 118px;

  @include media-breakpoint-down(xs) {

    padding: 4rem 1rem;

    a.btn {
      margin: 0;
    }

  }

}

/*/
  Page: Projects
/*/

.projects {

  #hero {
    @include background-photo-new("projects/hero", 50%, 100%);
  }

}

#projects-grid {


  // 67, 67, 67 -> 65, 66, 70
  @include background-color-adjust(0, 40, 170, .03);

  a {
    text-decoration: underline;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  h3 {
    margin-bottom: 2px;
    margin-top: 24px;
  }

  .intro h2 {
    margin-top: 38px;
  }

  .tour-link {

    a {
      background: url($assets + "play-button@2x.png") left center no-repeat;
      background-size: 24px;
      padding-left: 38px;
      text-decoration: none;
      line-height: 24px;
      display: inline-block;
    }

  }

  @include media-breakpoint-down(md) {

    padding: 0;

    .container-fluid {
      padding: 0;
      max-width: none;
    }

    .container {
      padding: 0;
      max-width: none;
    }

    .row {
      padding: 0 30px;
    }

    .row > div {

      @include container-padding(xs);
//      max-width: 540px;
      margin: 0 auto;

      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }

    }

    .row > div:after {
      content: "";
      display: block;
      width: 100vw;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      border: none;
      background: none;
      border-bottom: 1px solid #5b5e5f;
      margin-top: -1px;
      height: 4rem;
    }

    .row .project-image:after {
      content: none;
    }

  }

  .special a {
    display: inline;
  }
  .pipe {
    margin: 0 .25em;
  }

  .row .project-image {

    .inner {

      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: 90%;

      @include media-breakpoint-down(md) {
        background-position: left center;
        background-size: contain;
        margin-top: 0;
        margin-bottom: -4rem;
        //padding-bottom: 0;
      }

    }

  }

  @include media-breakpoint-up(lg) {

    padding: 0;

    .container-fluid {
        border-bottom: 1px solid $gray-medium;
    }

    .row > div {

      padding-bottom: 100px;
      padding-top: 100px;

      &:first-of-type {
        padding-right: 80px;
        border-right: 1px solid $gray-medium;
      }

      &.project-image {
        padding: 80px 45px 80px 15px;
        border-right: none;
      }

      &:last-of-type {
        padding-left: 80px;
      }

    }

  }

  #project-smoke-monitoring {
    .inner {
      background-image: url($assets + "projects/smoke-monitoring-illustration.jpg");
    }
  }

  #project-mustang-databrowser {
    .inner {
      background-image: url($assets + "projects/mustang-databrowser-illustration.jpg");
    }
  }

  #project-iris-seismic {

    @include media-breakpoint-up(md) {

      a {
        display: inline;
      }

      .tour-link a {
        display: inline-block;
      }

    }

  }

}

.projects #pre-footer {

  @include background-photo-new("projects/pre-footer", 100%, 100%);
  background-size: 100%;
  padding-bottom: 91px;
  padding-top: 118px;

  @include media-breakpoint-down(lg) {
    padding: 5rem 1rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 4rem 1rem;
  }

  .row {

    padding-bottom: 24px;
    padding-top: 24px;
    border: 1px solid #e7e7e7;
    border-left: none;
    border-right: none;
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      border: none;
      padding: 0;
      margin-bottom: 3em;
    }

    > div {
      padding-top: 0;
      padding-bottom: 0;
    }

  }

  @include media-breakpoint-down(xs) {

    li {
      background-position: left top 0.5em;
      background-size: .55em;
    }

    li:last-of-type {
//      margin-bottom: 0;
    }

  }

  @include media-breakpoint-down(xs) {
    a.btn {
      margin: 0;
    }
  }

}

#project-modal.modal {

  background: rgba(0, 0, 0, 1);

  .modal-dialog {

    max-width: none;
    margin: 0;
    height: 100%;

    .modal-content {
      background: none;
      height: 100%;
      border: 0;
      border-radius: initial;
    }

    .modal-header {

      border: 0;
      border-radius: 0;
      color: white;
      height: 100px;
      min-height: 100px;
      position: relative;
      z-index: 1001;

      .modal-title {
        font-family: "DINNextLTPro-Bold";
        font-size: 18px;
        line-height: 103px;
        padding-left: 41px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .close {
        background: url($assets + "projects/close-icon.png") center center no-repeat;
        background-size: 16px;
        overflow: hidden;
        height: 100px;
        width: 100px;
        display: block;
        text-indent: -2000px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 1;
        margin: 0;
        padding: 0;
      }

    }

    .modal-body {
      background: none;
      color: white;
      height: 100%;
      padding: 0;
      width: 100%;
    }

    .container-fluid {
      padding: 0;
    }

    .slides {
      padding-bottom: 100px;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      height: 100%;
    }

      .slick-track:before,
      .slick-track:after {
        // These interfere with flexbox, and is not needed.
        display: none;
      }

    .slick-slide {
      display: flex;
      flex-direction: column;
      // Using flexbox
      float: none;
      height: auto;
      padding: 0 65px;
    }

    .container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
//      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
    }

    .row {
      justify-content: flex-start;
    }

    .text {
      align-items: center;
      display: flex;
      flex-grow: 1;

      h2 {
        font-family: "AvenirNextLTPro-Medium";
      }

    }

    h2 {
      line-height: 1.2;
      font-family: "AvenirNextLTPro-Demi";
      font-size: 28px;
      margin: 0;
    }

    p {
      line-height: 1.2;
      font-family: "AvenirNextLTPro-Regular";
      font-size: 18px;
      margin: 1rem 0 0;
    }

    .image {

      @include make-col-ready();

      background-repeat: no-repeat;
      background-size: contain;

      img {
        width: 100%;
        height: auto;
//        border: 17px solid $gray-dark;
      }

    }

    .caption {

      @include make-col-ready();

      padding-top: 92px;

      @include media-breakpoint-up(md) {
        padding: 4rem 15px 0;
      }

    }

    .slick-arrow {

      border: none;
      position: absolute;
      overflow: hidden;
      top: 50%;
      transform: translate(0, -50%);
      height: 100px;
      width: 100px;
      z-index: 1001;
      text-indent: -2000px;
      background: transparent center center no-repeat;
      background-size: 25px;

      &.slick-prev {
        background-image: url($assets + "projects/back-arrow.png");
        background-position: left 40px center;
        left: 0;
//        margin-left: -15px;
      }

      &.slick-next {
        background-image: url($assets + "projects/forward-arrow.png");
        background-position: right 40px center;
//        margin-right: -15px;
        right: 0;
      }

    }

    @include media-breakpoint-up(xs) {

      @media (max-height: 100vw) {

        .image {
          @include make-col(7);
        }

        .caption {
          @include make-col(5);
        }

      }

    }

    @include media-breakpoint-up(sm) {

      .row {
        margin: 0;
      }

    }

    @include media-breakpoint-up(md) {

      @media (max-height: 100vw) {

        .image {
          @include make-col(8);
        }

        .caption {
          @include make-col(4);
        }

      }

    }

    @include media-breakpoint-up(lg) {

      .image {
        margin-left: 0;
      }

      .caption {
        margin-right: 0;
      }

      @media (max-height: 100vw) {

        .image {
          @include make-col(8);
        }

        .caption {
          @include make-col(4);
        }

      }

    }

    @include media-breakpoint-up(xl) {

      .caption {
        padding-left: 30px;
      }

      @media (max-height: 100vw) {

        .image {
          @include make-col(8);
        }

        .caption {
          @include make-col(4);
        }

      }

    }

    @include media-breakpoint-down(xl) {
      .text {
        margin: 0 10px;
      }
    }

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(md) {

      @media (max-width: 767.98px), (max-height: 50vw) {

        @media (max-width: 568.98px), (max-height: 100vw) {

          .modal-body {
            align-items: stretch !important;
          }

          .slides {

            height: 100%;

            @media (max-height: 100vw) {
              padding: 0;
            }

          }

          .slick-slider {
            height: 100%;
          }

          .slick-slide {
            padding: 0;
          }

          .slick-arrow {

            top: 100%;
            transform: none;

            &.slick-prev {
              margin: 0;
            }

            &.slick-next {
              margin: 0;
            }

          }

          .container {
            padding: 0;
          }

          .image {
            padding: 0;
          }

          .caption {
            padding-left: 30px;
            padding-right: 30px;
          }

          h2 {
            font-size: 18px;
          }

          p {
            font-size: 15px;
          }

          .text {
            padding: 0 30px;
          }

          @media (max-height: 756px) {

            .modal-header {

              height: 75px;
              min-height: 75px;

              .modal-title {
                padding-left: 30px;
                line-height: 78px;
              }

              .close {
                background-position: right 30px top 50%;
                height: 75px;
              }

            }

            .slides {
              padding-bottom: 75px;
            }

            .slick-arrow {

              height: 75px;

              &.slick-prev {
                background-position: left 30px center;
              }

              &.slick-next {
                background-position: right 30px center;
              }

            }

            .caption {
              padding-top: 30px;
            }

            .text {
              padding: 0 20px;
            }

          }

        }

      }

      @media (max-height: 60vw) {

        .modal-header {

          height: 20vh;
          min-height: 20vh;

          .modal-title {
            line-height: 21vh;
          }

          .close {
            height: 20vh;
          }

        }

      }

      @media (max-height: 100vw) {

        .slides {
          padding-bottom: 0;
        }

        .slick-arrow {
          display: none;
        }

        .container {
          justify-content: flex-end;
        }

        .text {
          padding: 0 20px 50px 20px;
        }

        .image {
          max-width: 98vh;
        }

        .caption {

          flex-basis: calc(100% - 98vh);
          max-width: none;
          padding-top: 0;

          h2 {
            font-size: 22px;
          }

        }

      }

      .container {
        max-width: none;
      }

    }

    @include media-breakpoint-down(xs) {

      @media (max-height: 100vw) {

        .caption {

//          padding: 0 15px;

          h2 {
            font-size: 18px;
          }

        }

      }

      @media (max-width: 360px) and (max-height: 640px),
             (max-width: 375px) and (max-height: 560px) {

        .modal-header {

          height: 60px;
          min-height: 60px;

          .modal-title {
            line-height: 63px;
            padding-left: 19px;
          }

          .close {
            height: 60px;
            background-position: right 20px top 50%;
          }

        }

        .slides {
          padding-bottom: 60px;
        }

        .slick-arrow {

          height: 60px;

          &.slick-prev {
            background-position: left 20px center;
          }

          &.slick-next {
            background-position: right 20px center;
          }

        }

        @media (max-width: 375px) and (max-height: 560px) {
          .image {
            padding: 0 20px;
          }
        }

        .caption {
          padding: 25px 20px 0 20px;
        }

        p {
          font-size: 15px;
        }

        .text {
          padding: 0 10px;
        }

      }

    }

  }

}


/*/
  Page: Join
/*/

.join {

  #hero.jumbotron {

    .tech-link {
      margin-top: 50px;
    }

    @include background-photo-new("join/collaborate", 50%, 0%);

    @include media-breakpoint-down(xs) {
      padding-top: 8rem;
    }

  }

  #connect {

    // 64, 67, 69
    @include background-color-adjust(0, 80, 120, .04);

    padding-top: 112px;
    padding-bottom: 112px;

    @include media-breakpoint-down(xs) {
      padding: 4rem 1rem;
    }

    h1 {

//      margin-bottom: 36px;

      @include media-breakpoint-down(xs) {
      }

    }

    h2 {

      background: url($assets + "monitor-icon@2x.png") right top no-repeat;
      background-size: 52px;
      color: $blue-medium;
      display: inline-block;
      height: 52px;
      line-height: 0;
      margin-bottom: 30px;
      padding-right: 84px;
      padding-top: 40px;
      vertical-align: bottom;

      @include media-breakpoint-down(xs) {
        background-position: right bottom .34em;
        background-size: 60px;
        height: auto;
        line-height: 1.2;
        margin-bottom: 1.25rem;
        padding: 0;
        width: auto;
      }

    }


    a.btn {
      margin: 20px 0 0 0;
    }

  }

  #pre-footer {

    @include background-photo-new("join/get-paid", 50%, 50%);
    padding-bottom: 225px;
    padding-top: 200px;

    a.btn {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      background-position: left 50% top 0%;
    }

    @include media-breakpoint-down(xs) {

      padding: 4rem 0;

      h1 {
      }

      .btn-primary {
        margin: 0;
      }

    }

  }

}


/*/
  Page: Contact
/*/

.contact {

  #hero {

    @include background-photo-new("contact/hero", 50%, 100%);

    @include media-breakpoint-down(lg) {
      background-size: cover;
    }

    h1 {
      margin-bottom: 1rem;
    }

  }

  #appointment {

    @include background-photo-new("contact/appointment", 50%, 50%);

    padding: 170px 0;

    h2 {
      background: url($assets + "calendar-icon@2x.png") right bottom 10px no-repeat;
      background-size: 51px;
      padding-right: 84px;
      display: inline-block;
    }

    @include media-breakpoint-down(xs) {

      @include container-padding(xs);

      .container {
        padding: 0;
      }

      h2 {
        padding-right: 69px;
      }

    }

    @include media-breakpoint-up(sm) {
      h2 {
        padding-top: 32px;
        margin-top: -20px;
      }
    }

  }
}


/*
320*2
375*2
414*2
736*2
  768*2
834*2
  1024*2
1112*2
1366*2

1152=576*2   =>  0 - 576
1536=768*2   =>  576 - 768
2048=1024*2  =>  768 - 992
2732=1366*2  =>  992 - 1200
*/
